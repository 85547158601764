@import "../StyleComponents/constants.scss";
@import '../StyleComponents/devices.scss';

div.modal{
    .maincard{
        background-color: white;
        min-width: 10rem;
        width: fit-content;
        height: fit-content;
    }
    visibility: hidden;
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    &[state="on"]{
        visibility: visible;
    }
}