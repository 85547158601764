@import '../StyleComponents/devices.scss';
@import '../StyleComponents/constants.scss';
@import '../StyleComponents/components.scss';

$logo-size:120px;
$brand-size: $logo-size/1.8;
$brand-margin:$logo-size/7.6;
$logo-size-laptop:80px;
$brand-size-laptop: $logo-size-laptop/1.8;
$brand-margin-laptop:$logo-size-laptop/4;

$logo-size-tablet:80px;
$brand-size-tablet: $logo-size-tablet/1.9;
$brand-margin-tablet:$logo-size-tablet/7.6;
$logo-size-mobile:60px;
$brand-size-mobile: $logo-size-mobile/1.8;
$brand-margin-mobile:$logo-size-mobile/7.6;





$video-ratio:0.5625;
$video-size-desktop:720px;
$video-size-laptop:600px;
$video-size-tablet:500px;
$video-size-mobile:280px;

@keyframes logoAnimation {
    from {
        transform: scale((5));
        opacity: 0;
    }

    to {
        transform: scale((1));
        opacity: 1;
    }
}

@keyframes logoBrandAnimation {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes descAnimation {
    from {
        transform: translateY(-40px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes typeBoxAnimation {
    0% {
        border-right: #eee 5px solid;

    }

    49% {
        border-right: #eee 5px solid;

    }

    50% {
        border-right: $backgroud-color 5px solid;
    }

    99% {
        border-right: $backgroud-color 5px solid;
    }
}

@keyframes typeAnimation {
    0% {
        width: 0px;

    }

    100% {
        width: 156px;
    }
}

@include desktop {
    @keyframes typeAnimation {
        0% {
            width: 0px;

        }

        100% {
            width: 230px;
        }
    }
}

@include laptop {
    @keyframes typeAnimation {
        0% {
            width: 0px;

        }

        100% {
            width: 175px;
        }
    }
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.corecheat {
    .space.focused {
        div.logo-border {

            animation: logoAnimation 1s;
            opacity: 1 !important;
        }

        div.cr-brand {
            animation: logoBrandAnimation 2s;
            opacity: 1 !important;
        }

        div.cr-desc {
            width: auto;
            animation: descAnimation 2s;
            opacity: 1 !important;

            .typeBox {
                opacity: 1 !important;
                animation: typeBoxAnimation 0.8s infinite;
                border-right: #eee 5px solid;

                div.typeContainer {
                    width: 156px;

                    @include laptop {
                        width: 175px;
                    }
                    @include desktop{
                        width: 230px;
                    }

                    animation:typeAnimation 3s;

                }
            }
        }

        div.cr-content {
            .youtube-video {
                animation: fadeInAnimation 2s;
                opacity:1 !important;
            }

            .buttons {
                .download {
                    animation: fadeInAnimation 3s;
                opacity:1 !important;
            }

                .github {
                    animation: fadeInAnimation 4s;
                opacity:1 !important;
            }
            }

            .shakey {
                animation: fadeInAnimation 4.5s;
                opacity:1 !important;
            }

        }
    }

    .space {
        @include scrollbar;

        .inner-page-container {
            a {
                font-family: 'Segoe UI Light';
            }

            @include desktop {
                margin-top: 30px;
            }

            @include laptop {
                margin-top: 35px;
            }

            @include tablet {
                margin-top: 40px;
            }

            @include mobile {
                margin-top: 50px;
            }

            .cr-header {
                .logo-border {
                    opacity: 0;

                    img {
                        @include desktop {
                            width: $logo-size;
                            height: $logo-size;
                        }

                        @include tablet {
                            width: $logo-size-tablet;
                            height: $logo-size-tablet;

                        }

                        @include laptop {
                            width: $logo-size-laptop;
                            height: $logo-size-laptop;

                        }

                        @include mobile {
                            width: $logo-size-mobile;
                            height: $logo-size-mobile;

                        }

                        border-radius: 50%;
                    }

                    width: fit-content;
                    margin: 0 auto;
                    display: inline-block;
                }

                .cr-brand {
                    display: inline-block;
                    opacity: 0;
                    position: relative;

                    @include desktop {
                        top: ($brand-margin);

                        h1 {
                            font-size: $brand-size;
                        }

                        margin-left: 15px;
                    }

                    @include laptop {
                        top: ($brand-margin-laptop);

                        h1 {
                            font-size: $brand-size;
                        }

                        margin-left: 15px;
                    }

                    @include tablet {
                        top: ($brand-margin-tablet);

                        h1 {
                            font-size: $brand-size-tablet;
                        }

                        margin-left: 15px;
                    }

                    @include mobile {
                        top: ($brand-margin-mobile);

                        h1 {
                            font-size: $brand-size-mobile;
                        }

                        margin-left: 5px;
                    }
                }

                .cr-desc {
                    opacity: 0;
                    color: #c2c2c2;
                    display: block;

                    @include desktop {
                        font-size: 24px;
                    }

                    @include laptop {
                        font-size: 18px;
                    }

                    @include tablet {
                        font-size: 16px;
                    }

                    @include mobile {
                        font-size: 16px;
                    }

                    text-align: center;

                    .typeBox {
                        display: -webkit-box;
                        white-space: nowrap;
                        margin: 0 auto;
                        width: fit-content;
                        padding: 0 2px;

                        .typeContainer {
                            display: -webkit-box;
                            overflow: hidden;
                            padding: 0 4px;
                        }
                    }


                }

                width: fit-content;
                margin: 0 auto;
                display: block;
            }

            .cr-content {
                width: 100%;
                height: fit-content;
                display: block;
                padding: 0 auto;

                @include desktop {
                    margin-top: 14px;
                }

                @include laptop {
                    margin-top: 12px;
                }

                @include tablet {
                    margin-top: 25px;
                }

                @include mobile {
                    margin-top: 25px;
                }

                div.youtube-video {
                    opacity: 0;

                    iframe {
                        @include desktop {
                            width: ($video-size-desktop);
                            height: ($video-size-desktop*$video-ratio);
                        }

                        @include laptop {
                            width: ($video-size-laptop);
                            height: ($video-size-laptop*$video-ratio);
                        }

                        @include tablet {
                            width: ($video-size-tablet);
                            height: ($video-size-tablet*$video-ratio);
                        }

                        @include mobile {
                            width: ($video-size-mobile);
                            height: ($video-size-mobile*$video-ratio);
                        }

                        display: inline-block;
                    }

                    width: fit-content;
                    height: auto;
                    display: block;
                    margin: 0 auto;
                }

                div.buttons {
                    width: auto;
                    display: block;

                    .button-container {
                        display: inline-block;
                        width: fit-content;
                        display: block;
                        margin: 0 auto;
                        margin-top: 10px;

                        @include desktop {
                            a {
                                border-radius: 2px;
                                margin: 0 3px;
                                padding: 14px 28px;

                                i {
                                    font-size: 1.5em;
                                    margin-right: 5px;
                                    transform: translateY(1px);
                                }

                                font-family: Segoe UI Light;
                                font-size:15px;
                                text-transform:uppercase;
                            }
                        }

                        @include tablet {
                            a {
                                border-radius: 2px;
                                margin: 0 3px;
                                padding: 14px 28px;

                                i {
                                    font-size: 1.5em;
                                    margin-right: 5px;
                                    transform: translateY(1px);
                                }

                                font-family: Segoe UI Light;
                                font-size:15px;
                                text-transform:uppercase;
                            }
                        }

                        @include laptop {
                            a {
                                border-radius: 2px;
                                margin: 0 3px;
                                padding: 14px 28px;

                                i {
                                    font-size: 1.5em;
                                    margin-right: 5px;
                                    transform: translateY(1px);
                                }

                                font-family: Segoe UI Light;
                                font-size:15px;
                                text-transform:uppercase;
                            }
                        }

                        @include mobile {
                            a {
                                border-radius: 2px;
                                margin: 0 3px;
                                padding: 14px 28px;

                                i {
                                    font-size: 1.5em;
                                    margin-right: 5px;
                                    transform: translateY(1px);
                                }

                                font-family: Segoe UI Light;
                                font-size:15px;
                                text-transform:uppercase;

                            }

                        }

                        a {
                            &:hover {
                                color: inherit;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }

                        .download {
                            display: inline-block;
                            background-color: #C62828;
                            opacity: 0;

                        }

                        .github {
                            display: inline-block;
                            background-color: #73878c;
                            opacity: 0;

                        }
                    }

                }

                div.shakey {
                    text-align: center;
                    font-size: 13px;
                    margin-top: 8px;
                    display: block;
                    opacity:0;
                    @include mobile {
                        font-size: 8px;
                    }
                }

            }
        }
    }
}