@import 'constants.scss';
@mixin dropshadowl0(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
}
@mixin dropshadowl1(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
}
@mixin dropshadowl2(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.31);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.31);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.31);
}
@mixin dropshadowl3(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.41);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.41);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.41);
}
@mixin dropshadowl4(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.58);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.58);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.58);
}
@mixin dropshadowl5(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.77);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.77);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.77);
}
@mixin scrollbar() {
	overflow-y: auto;
	-ms-overflow-style: auto;
	scrollbar-width: auto;
	/* Firefox */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 1px;
		background-color: #262626;
		width: 3px;
		display: inline-block;
	}
	&::-webkit-scrollbar {
		width: 10px;
		display: block;
		background-color: $backgroud-color;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 1px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		background-color: #131313;
	}
}
@mixin mini_scrollbar() {
	overflow-y: auto;
	-ms-overflow-style: auto;
	scrollbar-width: auto;
	/* Firefox */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 1px;
		background-color: #262626;
		width: 3px;
		display: inline-block;
	}
	&::-webkit-scrollbar {
		width: 6px;
		display: block;
		background-color: $backgroud-color;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 1px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		background-color: #131313;
	}
}

@mixin box-shadow-1 {
	-webkit-box-shadow: -8px 6px 14px -4px rgba(0, 0, 0, 0.77);
	-moz-box-shadow: -8px 6px 14px -4px rgba(0, 0, 0, 0.77);
	box-shadow: -8px 6px 14px -4px rgba(0, 0, 0, 0.77);
}