$backgroud-color:#090907;
$desc-color:#ddd;
$clickable-a-color:#0084ff;
$theme-color:#fd7000;
$text-color:#fff;
$theme-color-light: #fd7000;
$theme-color-darken: #fd7000;
$mobile-width: 320px;
$mobile-med-width: 425px;
$tablet-width: 768px;
$desktop-width: 1024px;
$ll-big-width:120px;
$ll-min-width:120px;
$ll-logo-mlf:-100px;
$ll-logo-mlf:-90;
@keyframes ls-loading-animation {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
		width: $ll-big-width;
		height: $ll-big-width;
		margin-left: 0;
		opacity: 1;
	}
	30% {}
	50% {}
	60% {
		margin-left: $ll-logo-mlf;
		height: $ll-big-width;
		width: $ll-big-width;
	}
	80% {
		margin-left: $ll-logo-mlf;
		height: $ll-min-width;
		width: $ll-min-width;
		transform: translate(-50%, -50%) rotateZ(-180deg);
	}
	90% {
		margin-left: $ll-logo-mlf;
		height: $ll-min-width;
		width: $ll-min-width;
		opacity: 1;
		transform: translate(-50%, -50%) rotateZ(-210deg);
	}
	100% {
		opacity: 0;
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
}

@keyframes ls-loading-animation-second {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
		opacity: 0;
	}
	30% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	41% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	50% {
		transform: translate(-50%, -50%) rotateZ(180deg);
	}
	60% {
		transform: translate(-50%, -50%) rotateZ(300deg);
	}
	75% {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
	85% {
		transform: translate(-50%, -50%) rotateZ(270deg);
	}
	90% {
		transform: translate(-50%, -50%) rotateZ(180deg);
		opacity: 1;
	}
	100% {
		transform: translate(-50%, -50%) rotateZ(0deg);
		opacity: 0;
	}
}

$llt-mlf:45px;
$llt-mls:15px;
@keyframes ls-loading-text-animation {
	0% {
		opacity: 0;
		margin-left: 0px;
	}
	5% {
		opacity: 0;
	}
	10% {
		opacity: 0.2;
	}
	15% {
		margin-left: $llt-mlf;
		opacity: 0.6;
	}
	24% {
		opacity: 0.8;
		margin-left: $llt-mlf;
	}
	30% {
		opacity: 1;
		margin-left: $llt-mlf;
	}
	34% {
		opacity: 1;
		margin-left: $llt-mls;
	}
	60% {
		text-shadow: none;
	}
	80% {
		text-shadow: 0 0 5px $theme-color, 0 0 25px $theme-color, 0 0 35px $theme-color, 0 0 45px $theme-color;
	}
	89% {
		opacity: 1;
	}
	95% {
		margin-left: 15px;
		opacity: 1;
	}
	100% {
		margin-left: 15px;
		opacity: 0;
		text-shadow: none;
	}
}

@keyframes ls-mainmenu-animation {
	0% {
		transform: rotateZ(0deg);
	}
	20% {
		transform: rotateZ(250deg);
	}
	30% {
		transform: rotateZ(180deg);
	}
	31% {
		transform: rotateZ(170deg);
	}
	40% {
		transform: rotateZ(120deg);
	}
	50% {
		transform: rotateZ(220deg);
	}
	60% {
		transform: rotateZ(330deg);
	}
	75% {
		transform: rotateZ(300deg);
	}
	80% {
		transform: rotateZ(360deg);
	}
	85% {
		transform: rotateZ(270deg);
	}
	90% {
		transform: rotateZ(180deg);
	}
	100% {
		transform: rotateZ(0deg);
	}
}
@-webkit-keyframes CardHover {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
}
@-moz-keyframes CardHover {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
}
@keyframes CardHover {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
}

@-webkit-keyframes glowing {
	0% {
		text-shadow: 0 0 2px $theme-color, 0 0 6px $theme-color, 0 0 10px $theme-color, 0 0 14px $theme-color;
	}
	100% {
		text-shadow: 0 0 4px $theme-color, 0 0 12px $theme-color, 0 0 20px $theme-color, 0 0 28px $theme-color;
	}
}

@-webkit-keyframes glowing-lower {
	0% {
		text-shadow: 0 0 1px $theme-color, 0 0 2px $theme-color, 0 0 4px $theme-color, 0 0 5px $theme-color;
	}
	100% {
		text-shadow: 0 0 2px $theme-color, 0 0 6px $theme-color, 0 0 10px $theme-color, 0 0 14px $theme-color;
	}
}

@-webkit-keyframes BrandHeaderComing {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	background-color: $backgroud-color;
	a {
		color: $text-color;
	}
}

* {
	font-family: 'Nunito', sans-serif;
	color: $text-color;
}

@mixin mobile {
	@media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

.ls-loader.closed {
	opacity: 0;
	transition: all ease-in-out 1s;
}

.ls-loader {
	transition: all ease-in-out 1s;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: $backgroud-color;
	z-index: 9999;
	.logo-carrier {
		img {
			animation: ls-loading-animation 3s;
			width: 80px;
			height: 80px;
			display: inline;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
			margin-left: -90px;
			opacity: 0;
		}
		img.second {
			animation: ls-loading-animation-second 10s infinite;
			display: block;
			width: 80px;
			height: 80px;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
			margin-left: -90px;
			opacity: 1;
		}
		h1 {
			animation: ls-loading-text-animation 10s infinite;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
			margin-top: 2px;
			font-size: 40px;
			color: $theme-color;
			font-family: 'Nunito', sans-serif;
			margin-left: 15px;
		}
	}
	@include mobile {
		.logo-carrier {}
	}
}

.ls-space {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	.ls-vision {
		width: 100%;
		height: 100%;
		display: block;
		position: static;
		-ms-overflow-style: none;
		scrollbar-width: none;
		/* Firefox */
	}
	::-webkit-scrollbar {
		width: 0px;
		display: none;
		background: transparent;
		/* Chrome/Safari/Webkit */
	}
	.ls-space-row {
		display: block;
		width: 100%;
		height: 100%;
		display: flex;
		.ls-space-column {
			flex-shrink: 0;
			display: flex;
			z-index: 5;
			display: inline-block;
			width: 100%;
			height: 100%;
			.space {
				width: 100%;
				height: 100%;
				display: block;
			}
			.space.focused {}
		}
	}
}

$logo-desktop-width:7em;
$logo-desktop-margin:0.25em;
$logo-tablet-width:5em;
$logo-tablet-margin:0.25em;
$logo-mobile-width:2.79em;
$logo-mobile-margin:0.23em;
$logo-title-ratio:1.7916;
.homepage {
	z-index: 20 !important;
	.space {
		margin: 0 auto;
		display: flex !important;
		justify-content: center;
		align-items: center;
		.center-container {
			display: block;
			width: 100%;
			.logo-carrier {
				margin: 0;
				left: 50%;
				transform: translateX(-50%);
				display: inline-block;
				position: relative;
				img.logo {
					width: $logo-desktop-width;
					height: $logo-desktop-width;
					display: inline;
					animation: ls-mainmenu-animation 10s infinite;
				}
				h1.title {
					margin-left: 5px;
					top: $logo-desktop-margin;
					position: relative;
					display: inline;
					font-size: $logo-desktop-width/$logo-title-ratio;
					
				}
				@include tablet {
					img.logo {
						width: $logo-tablet-width;
						height: $logo-tablet-width;
					}
					h1.title {
						font-size: $logo-tablet-width/$logo-title-ratio;
						top: $logo-tablet-margin;
					}
				}
				@include mobile {
					img.logo {
						width: $logo-mobile-width;
						height: $logo-mobile-width;
					}
					h1.title {
						font-size: $logo-mobile-width/$logo-title-ratio;
						top: $logo-mobile-margin;
					}
				}
			}
			.logo-bottom-headers {
				width: 100%;
				display: block;
				margin-top: 1.3em;
				.construction-brandheader {
					display: none;
					h1 {
						text-align: center;
						font-size: 1.1em;
						color: $theme-color-darken;
						animation: glowing-lower 2.5s ease-in-out infinite alternate;
					}
					@include mobile {
						font-size: 0.7em;
					}
					@include tablet {
						font-size: 1.1em;
					}
				}
				
			}
		}
	}
	.space.focused {
		.construction-brandheader {
			animation: BrandHeaderComing 1s;
			opacity: 1;
		}
	}
}

@keyframes FadeInUp {
	from {
		transform: translateY(-100px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes FadeInDown {
	from {
		transform: translateY(100px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}
@keyframes DiscordIn{
	from{

	}to{

	}
}
$discord-theme-color:#fff;
$discord-theme-back-color:#26262B;
$discord-theme-text-color-on-back:#26262B;

.discordbot {
	.space.focused {
		background-color: $discord-theme-back-color;
		button.addbottoserver {
			animation: FadeInDown 1.5s;
			opacity: 1 !important;
		}
		.pagebrand {
			animation: FadeInUp 1s;
			opacity: 1 !important;
		}
	}
	.space {
		transition: ease-in-out background 0.6s;
		background-color: $backgroud-color;
		margin: 0 auto;
		display: flex !important;
		justify-content: center;
		align-items: center;
		.center-container {
			div.buttoncontainer {
				display: flex;
				justify-content: center;
				padding-top: 10px;
				button.addbottoserver {
					color: #18191c;
					opacity: 0;
					border: 0;
					font-weight: 500;
					font-size: 1.3em;
					padding: 0.4em 2em;
					margin: 0 auto;
					position: relative;
					cursor: pointer;
					font-family: Whitney, Helvetica Neue, Helvetica, Arial, sans-serif;
					&:hover {
						transform: translateY(1px);
					}
					&:active {
						outline: none;
						border: none;
					}
					&:focus {
						outline: none;
						border: none;
					}
				}
				@include mobile {
					button.addbottoserver {
						font-size: 1.2em;
						padding: 0.2em 1.2em;
					}
				}
				@include tablet {
					button.addbottoserver {
						font-size: 1.3em;
						padding: 0.4em 2em;
					}
				}
			}
			.pagebrand {
				opacity: 0;
				margin-top: 0.5em;
				// text-shadow:
				// 0 0 2px $discord-theme-color,
				// 0 0 6px $discord-theme-color,
				// 0 0 10px $discord-theme-color,
				// 0 0 14px $discord-theme-color;
				font-size: 3.2em;
			}
			@include mobile {
				h1 {
					font-size: 1.6em !important;
				}
			}
			@include tablet {
				h1 {
					font-size: 2.3em;
				}
			}
			.clyde-logo {
				fill: #fff;
				width: 1.2em;
				margin-right: 0.15em;
			}
		}
	}
}
h1.pagebrand,h1.title{
    a.LYSEP {
        font-size: inherit;
        color: $theme-color;
        animation: glowing-lower 2.5s ease-in-out infinite alternate;
        display: inline-block;
        margin: 0;
        padding: 0;
    }
}
@mixin dropshadowl0(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
}
@mixin dropshadowl1(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.17);
}
@mixin dropshadowl2(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.31);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.31);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.31);
}
@mixin dropshadowl3(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.41);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.41);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.41);
}
@mixin dropshadowl4(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.58);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.58);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.58);
}
@mixin dropshadowl5(){
	-webkit-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.77);
	-moz-box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.77);
	box-shadow: -1px 7px 5px -2px rgba(0,0,0,0.77);
}
@mixin scrollbar() {
	overflow-y: auto;
	-ms-overflow-style: auto;
	scrollbar-width: auto;
	/* Firefox */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 1px;
		background-color: #262626;
		width: 3px;
		display: inline-block;
	}
	&::-webkit-scrollbar {
		width: 10px;
		display: block;
		background-color: $backgroud-color;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 1px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		background-color: #131313;
	}
}
@mixin mini_scrollbar() {
	overflow-y: auto;
	-ms-overflow-style: auto;
	scrollbar-width: auto;
	/* Firefox */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 1px;
		background-color: #262626;
		width: 3px;
		display: inline-block;
	}
	&::-webkit-scrollbar {
		width: 6px;
		display: block;
		background-color: $backgroud-color;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 1px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		background-color: #131313;
	}
}

@mixin box-shadow-1 {
	-webkit-box-shadow: -8px 6px 14px -4px rgba(0, 0, 0, 0.77);
	-moz-box-shadow: -8px 6px 14px -4px rgba(0, 0, 0, 0.77);
	box-shadow: -8px 6px 14px -4px rgba(0, 0, 0, 0.77);
}

$scrollbar-background:#121215;
$backgroud-color-2:#161616;
$backgroud-color-3:#181818;

$behind-color:#9e9e9e;
$discord-main-color:#7289DA;
$gmail-main-color:#DA524A;
@keyframes flying {
	0% {
		-webkit-transform: translate3d(0, -4px, 0);
		transform: translate3d(0, -4px, 0)
	}
	to {
		-webkit-transform: translate3d(0, 4px, 0);
		transform: translate3d(0, 4px, 0)
	}
}

.contacts {
    .space.focused{
        div.card{
			opacity: 1 !important;
			transition: ease-in-out 1s all;
        }
        @include desktop{
            .septillioner{
                animation: FadeInBottom 1s;
            }
            .lyceion{
                animation: FadeInBottom 1.5s;
            }
		}
		@include tablet{
            .septillioner{
                animation: FadeInBottom 1s;
            }
            .lyceion{
                animation: FadeInBottom 1.5s;
            }
        }
        @include mobile{
            .septillioner{
                animation: FadeInRight 1s;
            }
            .lyceion{
                animation: FadeInRight 1.5s;
            }
        }
    }
	.space {
		overflow: hidden;
        @include mobile{
            .inner-page-container{
				@include mini_scrollbar();
				.contact-profiles{
					.contact-profile{
						height: fit-content;
						&:nth-child(1){
							margin-bottom:2em !important;
						}
						display: block !important;
						margin: 0 auto !important;
					}
				}
            }
        }
        @include tablet{
            .inner-page-container{
				@include mini_scrollbar();

				.contact-profiles{
					display: block;
					width: fit-content;
					margin: 0 auto;
					.contact-profile{
						display: block;
						margin: 0;
						height: fit-content;
						&:nth-child(1){
							margin-right: 2em;
						}
						.card{
							width: auto;
						}
					}
				}
            }
        }
        @include desktop{
            .inner-page-container{
                @include scrollbar();
            }
        }
		.inner-page-container {
			width: 100%;
			height: 100%;
            display: block;
            padding-top: 2em;
            h1.pagebrand{
                text-align: center;
            }
			@include scrollbar();
			.contact-container {
				display: flex;
				display: block;
				justify-content: center;
				align-items: center;
				width: 100%;
				.contact-profiles {
					width: fit-content;
					padding: 1em 1em;
					margin: 0 auto;
					.contact-profile {
                        &:nth-child(1){
                            margin-right: 4em;
                        }
						display: inline-block;
						margin:0;
						.card {
                            @include box-shadow-1();
                            @include scrollbar();
                            opacity: 0;
							width: 18em;
                            height: fit-content;
                            padding-bottom: 3em;
							border-radius: 2px;
							border-bottom: $theme-color 4px solid;
							background-color: $backgroud-color-2;
							.profile-photo {
								display: block;
								img {
									animation: flying 1.5s infinite alternate;
									@include box-shadow-1();
									width: 60%;
									margin: 0 auto;
									height: auto;
									border-radius: 50%;
									display: block;
									margin-top: 2em;
								}
							}
							.nickname {
								margin-top: 3em;
								display: block;
								padding-left: 1em;
								label {
									font-size: 0.7em;
                                    display: block;
                                    margin: 0;
                                    color: $behind-color;
                                    transform: translateY(3px);
                                }
								a.title {
                                    padding-left: 3px;
                                    margin: 0;
									font-size: 1.4em;
									display: block;
								}
                            }
                            .about {
								margin-top: 0.4em;
								display: block;
                                padding-left: 1em;
                                padding-right: 0.7em;
								label {
									font-size: 0.7em;
                                    display: block;
                                    margin: 0;
                                    color: $behind-color;
                                }
								p.content {
                                    padding-left: 3px;
                                    margin: 0;
									font-size: 0.7em;
									display: block;
								}
                            }
                            .contacts{
                                padding-left: 1em;
								margin-top: 0.4em;
                                label {
									font-size: 0.7em;
                                    display: block;
                                    margin: 0;
                                    color: $behind-color;
                                }
                                tr.discord{
                                    i{
                                        color:$discord-main-color;
                                    }
                                }
                                tr.email{
                                    i{
                                        color:$gmail-main-color;
                                    }
                                }
                            }
                        }
                        
                    }
				}
			}
		}
	}
}
$privedit-color:#fc03c2;
$corecheat-color:#f50707;

.apps{
	.inner-page-container{
		padding-top:2em;
		.pagebrand{
			flex: 0 1 auto;
			display: flex;
			text-align: center;
			width: auto;
			display: block;
			h1{
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
		@include scrollbar();
		width: 100%;
		height: 100%;
		display: block;
		.container{
			padding-top: 1.3em;
			display:flex;
			flex: 1 1 auto;
			width: 100%;
			.row{
				width: 100%;
				display: flex;
				margin:0 auto;
				.appcard{
					opacity: 0;
					padding:0 1em;
					display: block;
					overflow: hidden;
					margin-bottom: 15px;
					transform: scale(1.0);
				
					.card-inner{
						display: block;
						cursor: pointer;
						&.others{
							.cc-container{
								background-color: $backgroud-color-2;
								width: 100%;
								display:flex;
								justify-content:center;
								align-items:center;
								height:292px;
								.center-carrier{
									display: inline-block;
									margin: 0 auto;
									h1{
										font-size: 25px;
										text-align: center;
									}
									.centerDiv{
										margin: 0 auto;
										display:flex;
										justify-content:center;
										align-items:center;
										margin-top: 20px;
										a.github{
											cursor:pointer;
											border-radius: 2px;
											margin: 0 3px;
											padding:  14px 28px;
											i{
												font-size: 1.5em;
												margin-right: 5px;
												transform: translateY(2px);
											}
											font-family: Segoe UI Light;
											font-size:15px;
											text-transform:uppercase ;
											display: inline-block;
											background-color:#73878c;
										}
									}
								}
							}
						}
						&:hover{
							.card-header{
								
								&[animationname=privedit]{
									transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
									transition: ease-in-out all 0.8s;
									background-color: #232323;
									background: linear-gradient(85deg, #161616, #161616, $privedit-color, $privedit-color, #161616);
    								background-size: 1000% 1000%;
									background-position:100% 40%
    								// -webkit-animation: CardHover 10s ease infinite;
    								// -moz-animation: CardHover 10s ease infinite;
    								// animation: CardHover 10s ease infinite;
								}
								&[animationname=corecheat]{
								transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
								transition: ease-in-out all 0.8s;
								background-color: #232323;
								background: linear-gradient(85deg, #161616, #161616, $corecheat-color,$corecheat-color  , #161616);
								background-size: 1000% 1000%;
								background-position:100% 40%
								// -webkit-animation: CardHover 10s ease infinite;
								// -moz-animation: CardHover 10s ease infinite;
								// animation: CardHover 10s ease infinite;
							}
							}
							
						}
						
						.card-header{
							display: flex;
							width: 100%;
							padding:0 1em;
							height: 100px;
							background-color: $backgroud-color-2;
							.card-img{
								width: 80px;
								height: 80px;
								vertical-align: center;
								display: inline-block;
								margin: auto 0;
								img{
									display: block;
									width: 80px;
									height: 80px;
								}
								order:1;
							}
							.card-brand{
								display: flex;
								width: 100%;
								order:2;
								margin: auto 0;
								text-align: right;
								padding-right: 16px;
								a.brand{
									width: 100%;
									display: block;
									font-size: 180%;
								}

							}
						}
						.card-content{
							padding: 20px 1em;
							display: block;
							background-color: $backgroud-color-3;
							a.desc{
								display: block;
								height: 128px;
								color: $desc-color;

							}
							a.button{
								display: block;
								font-weight: bold;
								color: $clickable-a-color;
								user-select: none;
								
							}
						}
				}
					
				}
			}
		}
	}
	.focused{
		.appcard{
			animation-timing-function: cubic-bezier(.39,.04,.75,.39);
			opacity:1 !important;
			transition: all 1s ease-in-out;
			&:nth-child(1){
				animation: FadeInRight 1s;
			}
			&:nth-child(2){
				animation: FadeInRight 1.2s;
			}
			&:nth-child(3){
				animation: FadeInRight 1.4s;
			}
			&:nth-child(4){
				animation: FadeInRight 1.6s ;
			}
			&:nth-child(5){
				animation: FadeInRight 1.8s ;
			}
			&:nth-child(6){
				animation: FadeInRight 2.2s ;
			}
			&:nth-child(7){
				animation: FadeInRight 2.4s;
			}
			&:nth-child(8){
				animation: FadeInRight 2.6s;
			}
			&:nth-child(9){
				animation: FadeInRight 2.8s;
			}
			&:nth-child(10){
				animation: FadeInRight 3s;
			}
			&:nth-child(11){
				animation: FadeInRight 3.2s;
			}
			&:nth-child(11){
				animation: FadeInRight 3.4s;
			}
		}
	}
}
.returnback {
	margin: 5px 5px 5px 5px;
	position: fixed;
	display: block;
	color: $theme-color;
	z-index: 10;
	i {
		color: $theme-color;
		position: relative;
		top: 1px;
		padding: 4px;
	}
	a {
		cursor: pointer;
		transition: ease-in-out 0.4s;
		text-shadow: 0 0 2px $theme-color, 0 0 3px $theme-color;
	}
	a:hover {
		text-shadow: 0 0 2px $theme-color, 0 0 6px $theme-color, 0 0 10px $theme-color, 0 0 14px $theme-color;
	}
}


@-webkit-keyframes FadeInUp {
	from {
	  -webkit-transform: translateY(-100px);
			  transform: translateY(-100px);
	  opacity: 0;
	}
	to {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px);
	  opacity: 1;
	}
  }
  
  @keyframes FadeInUp {
	from {
	  -webkit-transform: translateY(-100px);
			  transform: translateY(-100px);
	  opacity: 0;
	}
	to {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px);
	  opacity: 1;
	}
  }
  
  @-webkit-keyframes FadeInDown {
	from {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	  opacity: 0;
	}
	to {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px);
	  opacity: 1;
	}
  }
  
  @keyframes FadeInDown {
	from {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	  opacity: 0;
	}
	to {
	  -webkit-transform: translateY(0px);
			  transform: translateY(0px);
	  opacity: 1;
	}
  }

@-webkit-keyframes FadeOutLeft {
	from {
		opacity: 1;
		left: 0px;
		display: block;
	}
	to {
		display: none;
		opacity: 0;
		left: -100px;
	}
}

@-webkit-keyframes FadeOutRight {
	from {
		opacity: 1;
		right: 0px;
		display: block;
	}
	to {
		display: none;
		opacity: 0;
		right: -100px;
	}
}

.returnback.left {
	float: left;
	left: 0;
	&.active {
		display: block;
		animation: FadeInLeft 1s;
	}
	&.unactive {
		left: -100px;
		display: block;
		animation: FadeOutLeft 1s;
	}
}

.returnback.right {
	float: right;
	right: 0;
	&.active {
		display: block;
		animation: FadeInRight 1s;
	}
	&.unactive {
		right: -100px;
		display: block;
		animation: FadeOutRight 1s;
	}
	i.fa {
		float: right;
		position: relative;
		margin-left: 3px;
	}
}

.returnback.top {
	top: 0;
}

.returnback.bottom {
	bottom: 0;
}

.returnback.fow-up {
	i.fa::before {
		content: "\f077";
	}
}

.returnback.fow-down {
	i.fa::before {
		content: "\f078";
	}
}

.returnback.fow-left {
	i.fa::before {
		content: "\f053";
	}
}

.returnback.fow-right {
	i.fa::before {
		content: "\f054";
	}
}

.hide {
	display: none !important;
}