$mobile-width: 220px;
$mobile-med-width: 425px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1440px;
@mixin mobile {
	@media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
		@content;
	}
}
@mixin laptop {
	@media (min-width: #{$laptop-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}
@mixin computer {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}