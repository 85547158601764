@import '../StyleComponents/devices.scss';
@import '../StyleComponents/constants.scss';
@import '../StyleComponents/components.scss';
.poweredbylysep{
    &.bottom-left{
        bottom: 0;
        left:0;
        margin-left: 1200px;
        margin-bottom: 10px;
    }
    &.bottom-right{
        right:0;
        bottom: 0;
    }
    &.top-left{
        top:0;
        left:0;
    }
    &.top-right{
        top:0;
        right: 0;
    }
    .logo{
        width: 40px;
        height: 40px;
    }
    margin: 10px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    position: relative;
}