@import "../StyleComponents/constants.scss";
@import '../StyleComponents/devices.scss';

.page-brands {
    margin-top: 2.2em;

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-left: 0;
        margin: 0 auto;

        li {
            display: inline-flex;
            text-align: center;
            color: $theme-color;
            margin-left: 4px;
            margin-bottom: 4px;
        }

        a {
            transition: ease-in-out 0.4s;
            cursor: pointer;
            // text-shadow: 0 0 2px $theme-color, 0 0 3px $theme-color;
            font-size: 15px;
            border-radius: 2px;
            background-color: $theme-color;
            color: white;
            padding: 0.7rem 1.4rem;
            font-family: 'Segoe UI Light', Tahoma, Geneva, Verdana, sans-serif;
            text-transform: uppercase;

            i {
                font-size: 1.2em;
                margin-right: 5px;
                transform: translateY(2px);
                fill: white;
            }

            &[page='discordbot'] {
                background-color: $discord-main-color;

                &:hover {
                    background-color: #829bf5;
                }
            }

            &[page='apps'] {
                background-color: $theme-color;

                &:hover {
                    background-color: #f88628;
                }
            }

            &[page='contacts'] {
                background-color: #00909e;

                &:hover {
                    background-color: #00b3c3;
                }

            }

            &.donate {
                background-color: #00a700;

                &:hover {
                    text-decoration: none;
                    background-color: #00d600;
                }
            }
        }

        a:hover {
            opacity: 1;
            color: white;
            transform:scale(1.03);
            // text-shadow: 0 0 2px $theme-color, 0 0 6px $theme-color, 0 0 10px $theme-color, 0 0 14px $theme-color;
        }

    }

    @include mobile {
        ul {
            li {
                a {
                padding: 0.4rem 0.8rem;

                    font-size: 0.5em;
                    i {
                        font-size: 0.9em;
                margin-right: 3px;
                transform: translateY(0px);
                    }
                }
            }
        }
    }

    @include tablet {
        ul {
            li {
                a {
            padding: 0.5rem 1rem;

                    font-size: 0.9em;
                    i {
                        font-size: 1.1em;
                margin-right: 5px;
                transform: translateY(2px);
                    }
                }
            }
        }
    }
}